import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APIProvider, AdvancedMarker, Map } from '@vis.gl/react-google-maps';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Navbar from './Navbar';
import { fetchAllData, fetchDoctorReviews } from './NetworkManager';

const CORS_ANYWHERE = 'https://still-river-18416-618b43edfd9a.herokuapp.com/';
const DISTANCE_API_KEY = 'AIzaSyDF8JIB3pp29enLg_Snz-AZ5ndg8MwJkf0';

let mapCenter;
let mapZoom;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

async function updateDoctors (location, distance, gender, fetchedData, setDoctors, setDoctorsNearYou) {
  let doctorData = [];

  if (fetchedData.length > 0) {
    const fetchPromises = fetchedData.map(async (doctor, i) => {
      let address = doctor[5];
      let addressDelimited = encodeURIComponent(address.slice(0, -6));
      let locationDelimited = location.split(' ').join("%20");

      try {
        const distResponse = await fetch(CORS_ANYWHERE + 'https://maps.googleapis.com/maps/api/distancematrix/json?destinations=' + addressDelimited +
          '&origins=' + locationDelimited + '&units=imperial&key=' + DISTANCE_API_KEY);

        const reviewResponse = await fetchDoctorReviews(doctor[2], doctor[5], "hightolow");
      
        if (distResponse.ok) {
          console.log('Distance Matrix API called to calculate distance to ' + address);
          
          const data = await distResponse.json(); // parse the response data as JSON
          let textDistance = data.rows[0].elements[0].distance.text;
          let numDistance = parseFloat(textDistance.slice(0, -3));

          if ((distance >= numDistance) && ((gender === "Both") || (doctor[3] === gender))) {
            if (reviewResponse.rating) {
              doctorData.push([doctor[0], doctor[1], doctor[2], doctor[3], doctor[4], doctor[5], doctor[6], doctor[7], numDistance, reviewResponse.rating]);
            } else {
              doctorData.push([doctor[0], doctor[1], doctor[2], doctor[3], doctor[4], doctor[5], doctor[6], doctor[7], numDistance, null]);
            }
          }
        } else {
          throw new Error('API request failed: Distance from ' + location + ' to ' + address + ' could not be calculated');
        }
      } catch (error) {
        console.error(error);
      }
    });

    await Promise.all(fetchPromises);
  }

  setDoctors(doctorData);
  setDoctorsNearYou(doctorData.length);
}

async function updatePage (location, distance, gender, setDoctors, setDoctorsNearYou, setCoordinates, setLocation) {
  let doctorData = [];

  if (location) {
    const locationData = await fetchAllData(location); 
    doctorData = locationData || [];
  }

  await updateDoctors(location, distance, gender, doctorData, setDoctors, setDoctorsNearYou);

  if (doctorData.length > 0) {
    let addresses = [];

    let doctorsDisplayed;
    if (doctorData.length <= 10) {
      doctorsDisplayed = doctorData.length;
    } else {
      doctorsDisplayed = 10;
    }

    for (let i = 0; i < doctorsDisplayed; i++) {
      let address = doctorData[i][5];
      let addressDelimited = encodeURIComponent(address.slice(0, -6));

      fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + addressDelimited + '&key=AIzaSyAj58aXFyUyskZtQImgWDkJEMgv9HmTDfE')
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('API request failed: ' + address + ' could not be geocoded');
        }
      })
      .then(data => {
        if (data.results && data.results[0]) {
          console.log('Geocoding API called to geocode ' + address);
          const coordinates = data.results[0].geometry.location;
          addresses.push({ key: i, locationReadable: address, location: coordinates });
        
          if (addresses.length === doctorsDisplayed) {
            setCoordinates(addresses);
          }
        } else {
          console.log('No results found for address: ' + address);
        }
      })
      .catch(error => {
        console.error(error);
      });
    }
  }
};

const geocodeMapCenter = (location) => {
  const locationDelimited = encodeURIComponent(location);
  
  fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + locationDelimited + '&key=AIzaSyAj58aXFyUyskZtQImgWDkJEMgv9HmTDfE')
  .then(response => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('API request failed: ' + location + ' could not be geocoded');
    }
  })
  .then(data => {
    console.log('Maps Javascript API called to display ' + location);
    if (data.results[0]) {
      if (location === 'Washington') {
        mapZoom = 7;
        mapCenter = { lat: 47.50012, lng: -120.50147 }
      } else {
        mapZoom = 12;
        const coordinates = data.results[0].geometry.location;
        mapCenter = { lat: coordinates.lat, lng: coordinates.lng };
      }
    } else {
      console.log('No results found for user address: ' + locationDelimited);
    }
  })
  .catch(error => {
    console.error(error);
  });
}

const FindDoctor = () => {
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const containerStyle = { width: '100%', height: '100%' }; 
  const navigate = useNavigate();
  
  const [doctors, setDoctors] = useState([]);
  const [doctorsNearYou, setDoctorsNearYou] = useState([]);
  const [coordinates, setCoordinates] = useState([]);

  const [searchbar, setSearchbar] = useState([]);
  const [sortby, setSortby] = useState([]);
  
  const [newLocation, setLocation] = useState('');
  const [distance, setDistance] = useState('');
  const [gender, setGender] = useState('');

  let distanceDropdown = 'Distance (miles)';
  let genderDropdown = 'Gender (F/M)'
  let sortbyDropdown = 'Sort By';

  const query = useQuery();
  let location = query.get('location');

  useEffect(() => {
    const loadGoogleMapsApi = () => {
      if (!window.google || !window.google.maps) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD3wyBA25RlI0KUkRUdQz1geUY2Jk1AB_0`;
        script.async = true;
        script.onload = () => {
          setIsApiLoaded(true);
        };
        script.onerror = () => {
          console.error('Failed to load Google Maps API');
        };

        document.body.appendChild(script);
      } else {
        setIsApiLoaded(true);
      }
    };

    loadGoogleMapsApi();
  }, []);

  useEffect(() => {
    if (location) {
      setSearchbar(location);
      setLocation(location);
    } else {
      setSearchbar("Washington");
      setLocation("Washington");
    }

    setDistance(500);
    setGender("Both");
  }, [location]);

  useEffect(() => {
    updatePage(newLocation, distance, gender, setDoctors, setDoctorsNearYou, setCoordinates, setLocation);
    geocodeMapCenter(newLocation);
  }, [newLocation, distance, gender]);

  const handleNewSearch = (value, setValue) => {
    setValue(value);
    setSortby(sortbyDropdown);
  };

  const handleSortBy = (e) => {
    let doctorsSorted = [];

    if (e.target.value === 'rating') {
      // descending order
      doctorsSorted = doctors.sort(function(a, b) {
        return b[9] - a[9];
      });
      console.log(doctorsSorted);
    } else if (e.target.value === 'distance') {
      // ascending order
      doctorsSorted = doctors.sort(function(a, b) {
        return a[8]-b[8];
      });
      console.log(doctorsSorted);
    } else {
      doctorsSorted = doctors;
    }

    setSortby(e.target.value);
    setDoctors(doctorsSorted);
  }

  const handlePinClick = (lat, lng) => {
    let doctorsAtAddress = [];
    let doctorsNearby = [];
    let address;

    for (let i = 0; i < coordinates.length; i++) {
      if ((coordinates[i].location.lat === lat) && (coordinates[i].location.lng === lng)) {
        address = coordinates[i].locationReadable;
      }
    }

    for (let j = 0; j < doctors.length; j++) {
      if (address === doctors[j][5]) {
        doctorsAtAddress.push(doctors[j]);
      } else {
        doctorsNearby.push(doctors[j]);
      }
    }
  
    setDoctors(doctorsAtAddress.concat(doctorsNearby));
  };

  const handleNoReviews = (doctor) => {
    if (doctor[9]) {
      return doctor[9] + "/5 stars";
    } else {
      return "N/A";
    }
  };

  const handleLearnMore = (doctor) => {
    navigate(`/doctor-profile?name=${doctor[2]}&credential=${doctor[1]}&gender=${doctor[3]}&type=${doctor[4]}&address=${doctor[5]}&phone=${doctor[6]}`);
  };

  return (
    <div>
      <div className="container">
        <Navbar/>
      </div>
      <div className="filter-container d-flex justify-content-end">
        <div className="search-box map-filter">
          <div className="filter-search">
            <input 
              id="location-filter"
              type="text" 
              placeholder="Enter city, state, or zip" 
              value={searchbar} 
              onChange={(e) =>
                setSearchbar(e.target.value)} 
            />
            <button id="searchButton" onClick={() => handleNewSearch(searchbar, setLocation)}>
              Search
            </button>
            <select 
              value={distance} 
              onChange={(e) => handleNewSearch(e.target.value, setDistance)}
              placeholder="Select Distance..."
            >
              <option value="500">{distanceDropdown}</option>
              <option value="1">&lt; 1 miles</option>
              <option value="5">&lt; 5 miles</option>
              <option value="10">&lt; 10 miles</option>
              <option value="50">&lt; 50 miles</option>
            </select>
            <select
              value={gender}
              onChange={(e) => handleNewSearch(e.target.value, setGender)}
              placeholder="Select Gender..."
            >
              <option value="Both">{genderDropdown}</option>
              <option value="F">Female</option>
              <option value="M">Male</option>
            </select>
          </div>
          <div className="sort-search">
            <select
              value={sortby}
              onChange={(e) => handleSortBy(e)}
              placeholder="Select Gender..."
            >
              <option value="none">{sortbyDropdown}</option>
              <option value="rating">Rating</option>
              <option value="distance">Distance</option>
            </select>
          </div>
        </div>
      </div>

      <div className="fluid-container">
        <div className="map-container">
          <div style={{ height: '100vh', width: '100%' }}>
            {isApiLoaded ? (
              <APIProvider apiKey={process.env.AIzaSyD3wyBA25RlI0KUkRUdQz1geUY2Jk1AB_0}>
                <Map 
                  mapId='93379d636e515f6e'
                  mapContainerStyle={containerStyle}
                  zoom={mapZoom}
                  center={mapCenter}
                >
                  {coordinates.map((location) => (
                    <AdvancedMarker 
                      key={location.key}
                      position={{
                        lat: location.location.lat,
                        lng: location.location.lng
                      }} 
                      onClick={() => handlePinClick(location.location.lat, location.location.lng)}
                    />
                  ))}
                </Map>
              </APIProvider>
            ) : (
              <div>Loading Map...</div>
            )}
          </div>
        </div>
        <div className="cards-container">
          <div id='cards-title'>
            <h3>Ob/Gyn near you</h3>
            <p>{doctorsNearYou} found near you</p>
          </div>
          {Array.isArray(doctors) && doctors.length > 0 ? (
            doctors.map((doctor, index) => (
              // Information also inside FindDoctor.js
              <div className="doctor-card">
                <div className="doctor-info">
                  <h3>DR. {doctor[2]}</h3> {/* name */}
                  <p>{doctor[1]}</p> {/* credential */}
                  <p>{doctor[3]}</p> {/* gender */}
                  <p>{doctor[4]}</p> {/* type */}
                  <p>Address: {doctor[5]}</p> {/* address */}
                  <p>Phone: {doctor[6]}</p> {/* phone */}
                </div>
                <div className='search-info'>
                  <div className='sort-by-info'>
                    <h4>{handleNoReviews(doctor)}</h4> {/* overall rating */}
                    <p>Distance: {doctor[8]} miles</p> {/* distance */}
                  </div>
                  <div className='more-info'>
                    <button id="learnMoreButton" onClick={() => handleLearnMore(doctor)}>
                      Learn More
                    </button>
                  </div>
                </div>
              </div>

            ))
          ) : (
            <p id='noDoctorsError'>No doctors found. Please enter a valid location in Washington State.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FindDoctor;