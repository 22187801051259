import axios from 'axios';

export async function fetchNews({ keywords = 'obgyn' } = {}) {
  const BASE_URL = 'https://api.currentsapi.services/v1/search';
  const API_KEY = 'Wo-HZ4q5GnniFD7MY7Ppeqt01joqtFvTc4mlNBVQVGdk-1Ym';

  const params = new URLSearchParams({
    keywords,
    apiKey: API_KEY
  });

  const url = `${BASE_URL}?${params.toString()}`;
  console.log('Request URL:', url);

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Data:', data);

    return data.news.map(newsItem => ({
      title: newsItem.title,
      url: newsItem.url,
      imageUrl: newsItem.image,
    }));
  } catch (error) {
    console.error('Error fetching news:', error);
    throw error;
  }
}


export async function fetchAllData(geoLocation) {
  const baseUrl = 'https://clinicaltables.nlm.nih.gov/api/npi_idv/v3/search';
  const params = new URLSearchParams({
    terms: 'OB-GYN',
    q: `addr_practice.state:WA AND ${geoLocation} AND provider_type:Obstetrics & Gynecology`,
    df: 'NPI,name.credential,name.full,gender,provider_type,addr_practice.full,addr_practice.phone,licenses',
    maxList: 25,
  });

  const url = `${baseUrl}?${params.toString()}`;
  console.log('Request URL:', url);

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log('Data:', data);
    return data[3];
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

//get single doctor data
export async function fetchDoctorData(doctorName) {
  const baseUrl = 'https://clinicaltables.nlm.nih.gov/api/npi_idv/v3/search';
  const params = new URLSearchParams({
    terms: doctorName,
    q: 'addr_practice.state:WA',
    // maxList: 7,
    df: 'NPI,name.credential,name.full,gender,provider_type,addr_practice.full,addr_practice.phone,licenses',
  });

  const url = `${baseUrl}?${params.toString()}`;
  console.log('Request URL:', url);

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();

    console.log('Total Results:', data[0]);
    console.log('NPI Codes:', data[1]);
    console.log('Extra Data:', data[2]);
    console.log('Display Strings:', data[3]);
    console.log('Code System:', data[4]);

    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

// const proxyUrl = 'https://intense-earth-12088-3f9f7d790bb9.herokuapp.com/';
const proxyUrl = 'https://still-river-18416-618b43edfd9a.herokuapp.com/';

// Inside NetworkManager.js

const GOOGLE_API_KEY = 'AIzaSyDsgAevVj4sKYfQNBgOfv64QDA-XXeA4zc';
export async function fetchDoctorReviews(doctorName, location, sortOrder = 'hightolow') {
  const baseUrl = 'https://maps.googleapis.com/maps/api/place/findplacefromtext/json';
  const params = new URLSearchParams({
    input: `Dr. ${doctorName} ${location}`,
    inputtype: 'textquery',
    fields: 'place_id',
    key: GOOGLE_API_KEY,
  });

  const url = `${baseUrl}?${params.toString()}`;
  const proxiedUrl = `${proxyUrl}${url}`;

  try {
    const response = await axios.get(proxiedUrl);
    console.log('Response from fetchDoctorReviews:', response.data);
    if (response.data.candidates && response.data.candidates.length > 0) {
      const placeId = response.data.candidates[0].place_id;
      return await fetchPlaceDetails(placeId, sortOrder);
    }
    return { reviews: [] }; // Ensure reviews is an array even if no candidates found
  } catch (error) {
    console.error('Error fetching doctor reviews:', error);
    return { reviews: [] }; // Ensure reviews is an array even in case of error
  }
}


export async function fetchPlaceDetails(placeId, sortOrder) {
  const baseUrl = 'https://maps.googleapis.com/maps/api/place/details/json';
  const params = new URLSearchParams({
    place_id: placeId,
    fields: 'name,rating,reviews',
    key: GOOGLE_API_KEY,
  });

  const url = `${baseUrl}?${params.toString()}`;
  const proxiedUrl = `${proxyUrl}${url}`;

  try {
    console.log('Fetching place details with URL:', proxiedUrl);
    const response = await axios.get(proxiedUrl);
    let result = response.data.result;

    if (sortOrder === 'mostRecent') {
      result.reviews.sort((a, b) => new Date(b.time) - new Date(a.time));
    } else if (sortOrder === 'hightolow') {
      result.reviews.sort((a, b) => b.rating - a.rating);
    } else if (sortOrder === 'lowtohigh') {
      result.reviews.sort((a, b) => a.rating - b.rating);
    }

    console.log('Response from fetchPlaceDetails:', result);
    return result;
  } catch (error) {
    console.error('Error fetching place details:', error);
    throw error;
  }
}
