import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { fetchDoctorReviews } from './NetworkManager';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const DoctorProfile = () => {
  const query = useQuery();
  const doctor = {
    name: query.get('name'),
    credential: query.get('credential'),
    gender: query.get('gender'),
    type: query.get('type'),
    address: query.get('address'),
    phone: query.get('phone'),
  };

  const [reviews, setReviews] = useState({ reviews: [] });
  const [sortOrder, setSortOrder] = useState('hightolow');

  useEffect(() => {
    if (doctor.name) {
      fetchDoctorReviews(doctor.name, doctor.address, sortOrder)
        .then(data => {
          setReviews(data);
        })
        .catch(error => {
          console.error('Error fetching reviews:', error);
        });
    }
  }, [doctor.name, doctor.address, sortOrder]);

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleMissingCredential = (doctor) => {
    if (!doctor.credential) {
      return "No credentials provided."
    } else {
      return doctor.credential;
    }
  };

  return (
    <div>
      <div className="container">
        <Navbar />
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-md special-container">
            {doctor ? (
              <div>
                <div className="card mb-4">
                  <div className="card-body">
                    <h2>{doctor.name}</h2> {/* name */}
                    <h5 className="text-muted">{doctor.type}</h5> {/* provider type */}
                    <h5 className="text-muted">{handleMissingCredential(doctor)}</h5>
                    <p>
                      <span className="badge bg-success" style={{ backgroundColor: '#28a745' }}>{doctor.gender}</span> {/* gender */}
                    </p>
                    <p className="clinic-address">
                      <strong>{doctor.address}</strong><br /> {/* address */}
                      <i className="fas fa-phone"></i> {doctor.phone} {/* phone */}
                    </p>
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-body">
                    <h4>Reviews</h4>
                    <div className="mb-3">
                      <label htmlFor="sortReviews" className="form-label">Sort by:</label>
                      <select id="sortReviews" className="form-select" value={sortOrder} onChange={handleSortChange}>
                        <option value="mostRelevant">Most Relevant</option>
                        <option value="mostRecent">Most Recent</option>
                        <option value="hightolow">High To Low</option>
                        <option value="lowtohigh">Low To High</option>
                      </select>
                    </div>
                    {reviews.reviews && reviews.reviews.length > 0 ? (
                      <div>
                        <p>Overall Rating: {reviews.rating}</p>
                        {reviews.reviews.map((review, index) => (
                          <div key={index}>
                            <p>{review.text}</p>
                            <p><strong>Rating:</strong> {review.rating}</p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>No reviews available.</p>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <p>Loading doctor information...</p>
            )}
          </div>
        </div>
      </div>
      <script src="https://kit.fontawesome.com/a076d05399.js"></script>
    </div>
  );
};

export default DoctorProfile;