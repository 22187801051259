import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import '../App.css';
import { fetchNews } from './NetworkManager';

function Home() {
  const [articles, setArticles] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [location, setLocation] = useState('');
  const wrapperRef = useRef(null);
  const sourcesRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getNews = async () => {
      try {
        const newsArticles = await fetchNews({ keywords: 'obgyn' });
        setArticles(newsArticles);
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    getNews();
  }, []);

  const handleScroll = (direction) => {
    let cardWidth;
    let visibleCards;
    
    if (window.innerWidth <= 768) {
      visibleCards = 3;
      cardWidth = wrapperRef.current.offsetWidth / visibleCards;
    } else {
      visibleCards = 4;
      cardWidth = wrapperRef.current.offsetWidth / visibleCards;
    }
  
    const totalCards = articles.length;
    const maxScroll = cardWidth * (totalCards - visibleCards);
  
    let newScrollPosition = scrollPosition + direction * cardWidth;
    if (newScrollPosition < 0) newScrollPosition = 0;
    if (newScrollPosition > maxScroll) newScrollPosition = maxScroll;
  
    setScrollPosition(newScrollPosition);
    sourcesRef.current.style.transform = `translateX(-${newScrollPosition}px)`;
  };
  

  const handleSearchClick = async () => {
    let locationDelimited = location.split(' ').join("%20");
    try {
      const response = await fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' 
        + locationDelimited + '&key=AIzaSyAj58aXFyUyskZtQImgWDkJEMgv9HmTDfE');
      
      if (response.ok) {
        console.log('Geocoding API called to geocode ' + location);
        const data = await response.json();

        if (data.results[0]) {
          document.getElementById("searchErrorMessage").innerText = '';
          navigate(`/find-doctor?location=${location}`);
        } else {
          document.getElementById("searchErrorMessage").innerText = 'Please enter a valid location in Washington State.';
          throw new Error('API request failed: ' + location + ' could not be geocoded');
        }
      } else {
        document.getElementById("searchErrorMessage").innerText = 'Please enter a valid location in Washington State.';
        throw new Error('API request failed: ' + location + ' could not be geocoded');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="header-section-a">
        <Navbar />
        <div className="search-section">
          <p>We're Glad You're Here</p>
          <h1>Find the Perfect OB/GYN for You</h1>
          <div className="search-box">
            <input 
              type="text" 
              placeholder="Enter city or zip" 
              value={location} 
              onChange={(e) => setLocation(e.target.value)} 
            />
            <button id="searchButton" onClick={handleSearchClick}>
              Search
            </button>
          </div>
          <p id='searchErrorMessage'></p>
        </div>
      </div>
      <div className="container">
        <h2>What can we provide for you?</h2>
        <div className="infographic-section">
          <div className="infographic-item">
            <img src="img/interactive.png" alt="Interactive Map Search" className="infographic-image" />
          </div>
          <div className="infographic-item">
            <img src="img/doc.png" alt="Doctor Profiles" className="infographic-image" />
          </div>
          <div className="infographic-item">
            <img src="img/community.png" alt="Community-Provided Reviews" className="infographic-image" />
          </div>
        </div>
        <div className="featured-sources-container">
          <h2>Featured sources</h2>
          <div className="buttons-container d-flex justify-content-end">
            <button className="arrow-button arrow-left" onClick={() => handleScroll(-1)}>‹</button>
            <button className="arrow-button arrow-right" onClick={() => handleScroll(1)}>›</button>
          </div>
          <div className="featured-sources-wrapper" ref={wrapperRef}>
            <div className="featured-sources" ref={sourcesRef}>
              {articles.map((article, index) => (
                <div className="source-card" key={index}>
                  <a href={article.url} target="_blank" rel="noopener noreferrer">
                    <img src={article.imageUrl} alt={article.title} className="article-image" onError={(e) => e.currentTarget.src = 'img/fallback.png'} />
                  </a>
                  <a href={article.url} target="_blank" rel="noopener noreferrer">
                    <h3>{article.title}</h3>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );  
}

export default Home;
