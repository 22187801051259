import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../Navbar.css';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle('dark-mode', !darkMode);
  };

  return (
    <div className="navbar-container">
      <div className={`navbar ${darkMode ? 'dark-mode' : ''}`}>
        <Link to="/" className="navbar-logo">Elleviate</Link>
        <button className="navbar-toggle" onClick={toggleMenu}>▼</button>
        <div className={`navbar-menu ${menuOpen ? 'show' : ''}`}>
          <Link to="/find-doctor" className="active-button" onClick={toggleMenu}>Find a Doctor</Link>
          <Link to="/more-info" className="active-button" onClick={toggleMenu}>More Info</Link>
          <Link to="/about-us" className="active-button" onClick={toggleMenu}>About Us</Link>
        </div>
        <button className="dark-mode-toggle" onClick={toggleDarkMode}>
          {darkMode ? 'Light Mode' : 'Dark Mode'}
        </button>
      </div>
    </div>
  );
};

export default Navbar;
