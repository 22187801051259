import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import '../App.css';

const services = [
  { 
    title: "Wellness Exams", 
    image: "img/wellness.png"
  },
  { 
    title: "Contraception Counseling", 
    image: "img/contraception.png" 
  },
  { 
    title: "Treat Menstrual Disorders", 
    image: "img/treat.png" 
  },
  { 
    title: "Discuss Sexual Health", 
    image: "img/discuss.png" 
  },
  { 
    title: "Provide Pregnancy Care", 
    image: "img/provide.png" 
  },
  { 
    title: "Perform Gyno. Surgery", 
    image: "img/perform.png" 
  }
];

const MoreInfo = () => {
    const navigate = useNavigate(); 
    const handleClick = () => {
    navigate('/find-doctor');
  };

  return (
    <div>
      <div style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + '/img/wave1.png'})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden'
      }}>
        <div className="container">
          <Navbar />
        </div>
        <div className="info-section">
          <div className="info-text">
            <h2>Gynecologists are here for you and your health</h2>
            <p>
              Gynecologists focus on the health of the female reproductive system and are always available to provide help or care.
            </p>
            <button className="find-doctor-button" onClick={handleClick}>Find a Doctor</button>
          </div>
          <div className="info-image">
            <div className="image-wrapper">
              <img src="img/topGyn.png" alt="Gynecologist consultation 1" className="image1" />
              <img src="img/bottomGyn.png" alt="Gynecologist consultation 2" className="image2" />
            </div>
          </div>
        </div>
      </div>
      <section className="more-info-section" style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + '/img/blob.png'})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden'
      }}>
        <h2>What services can <br/> gynecologists provide?</h2>
        <div className="more-info-cards">
          {services.map((service, index) => (
            <div key={index} className="more-info-card">
              <img src={service.image} alt={service.title} className="more-info-image" />
            </div>
          ))}
        </div>
      </section>
      <section
        className="find-gynecologist-section"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + '/img/wave3.png'})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <div className="find-gynecologist-container">
          <div className="image-side">
            <img
              src={`${process.env.PUBLIC_URL}/img/gyno.png`}
              alt="Gynecologist Consultation"
              className="gyno-image"
            />
          </div>
          <div className="text-side">
            <div className="rectangle-bg"></div> 
            <h2>Now, where can you find a gynecologist?</h2>
            <p>Gynecologists are available in many different settings, including:</p>
            <ul className="options-list">
              <li className="icon-text-container">
                <div className="icon-container">
                  <img src="img/hospital.png" alt="hospital icon" className="icon" />
                </div>
                <div className="text-container">
                  <span>Hospitals</span>
                </div>
              </li>
              <li className="icon-text-container">
                <div className="icon-container">
                  <img src="img/clipboard.png" alt="clipboard icon" className="icon" />
                </div>
                <div className="text-container">
                  <span>Private Clinics</span>
                </div>
              </li>
              <li className="icon-text-container">
                <div className="icon-container">
                  <img src="img/peopleicon.png" alt="people icon" className="icon" />
                </div>
                <div className="text-container">
                  <span>Planned Parenthood</span>
                </div>
              </li>
            </ul>
            <button className="find-doctor-button" onClick={handleClick}>
              Find a Doctor
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MoreInfo;
